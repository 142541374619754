import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'

const PriceList = () => {
  const data = useStaticQuery(graphql`
  query {
    yaml: allPriceListYaml {
      nodes {
        name: category
        id
        items {
          price
          text
        }
      }
    }
  }`)

  const categories = data.yaml.nodes

  return (
    <Layout>
      {categories.map(category =>
        <div className="flex flex-col w-full mx-auto my-12 max-w-screen-lg price-list-category" key={category.id}>
          <h3 id={category.name} className="my-4 mt-3 text-2xl uppercase lg:text-4xl">{category.name}</h3>
          <ul>
            {category.items.map(item =>
              <li key={item.id} className="flex justify-between w-full">
                <span>{item.text}</span>
                <figure>${parseFloat(item.price).toFixed(2)}</figure>
              </li>
            )}
          </ul>
        </div>
      )}
      <div id="download" className="flex justify-center w-full pt-12 pb-6">
        <a href="/pdf/price-list.pdf" className="text-2xl lg:text-4xl hover:underline">Download PDF</a>
      </div>
    </Layout>
  )
}

export default PriceList
